import React, { useEffect, useState } from "react";
import styles from "./VerifyTicket.module.css";
import { useSearchParams } from "react-router-dom";
import { verifyTicket } from "../../api/ticket";
import SERVER_BASE_URL from "../../utils/base_url";
import { paste } from "../../utils/helpers/pasteWithLang";

const VerifyTicket = () => {
  const [searchParams] = useSearchParams();
  const [ticketStatus, setTicketStatus] = useState({});
  const [loading, setLoading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState({});

  const checkTicketStatus = async () => {
    setLoading(true);
    try {
      const response = await verifyTicket(searchParams.get("orderId"));
      if (response.status === 500) {
        setTicketStatus({
          ErrorCode: "2",
          ErrorMessage: response.response.data.message,
        });
      } else {
        setTicketStatus(response.data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkTicketStatus();
  }, []);

  const downloadFile = async (fileURL, ticketNo) => {
    try {
      const filename = fileURL.split("/").at(-1);
      const response = await fetch(`${SERVER_BASE_URL}/server_static${fileURL}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      });

      const total = parseInt(response.headers.get('content-length'), 10);
      let loaded = 0;

      const reader = response.body.getReader();
      const stream = new ReadableStream({
        start(controller) {
          function push() {
            reader.read().then(({ done, value }) => {
              if (done) {
                controller.close();
                setDownloadProgress((prev) => ({
                  ...prev,
                  [ticketNo]: 100,
                }));
                return;
              }
              loaded += value.byteLength;
              setDownloadProgress((prev) => ({
                ...prev,
                [ticketNo]: Math.round((loaded / total) * 100),
              }));
              controller.enqueue(value);
              push();
            });
          }
          push();
        },
      });

      const blob = await new Response(stream).blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("File didn't download: ", error);
    }
  };


  return (
    <div className={styles.mainDiv}>
      {loading ? (
        <div className={styles.loading}>
          {paste(['Biletleriňiz barlanýar', 'Ваши билеты проверяются', 'Your tickets are being checked'])}... <div className={styles.loader}></div>
        </div>
      ) : ticketStatus.ErrorCode === "0" ? (
        <div className={styles.successContainer}>
          <div>
            <span style={{ color: "green", margin: "20px", display: 'block', marginBottom: '50px' }}>
              {paste(['Siziň tölegiňiz üstünlikli geçdi!', 'Ваша оплата прошла успешно!', 'Your payment was successful!'])}
              {" "}
              {paste(['Bilediňizi ýükläp alyp bilersiňiz.', 'Вы можете скачать свой билет.', 'You can download your ticket.'])}
            </span>

            <div style={{ marginBottom: '30px' }}> {paste(['Bron kodyňyz', 'Ваш код бронирования', 'Your reservation code'])}{" "}: <b>{ticketStatus?.reservedCode}</b> </div>
            <ol className={styles.ticketList}>
              {ticketStatus.tickets.map((ticket) => (
                <li key={ticket.ticketNo} className={styles.ticketItem}>
                  <div className={styles.ticketDetails}>
                    <div className={styles.ticketInfo}>
                    <span className={styles.ticketFloor}>{ticket.floorName}</span> / 
                    <span className={styles.ticketLine}>{ticket.lineName} hatar</span> / 
                    <span className={styles.ticketSeat}>{ticket.seatNumber} ýer</span>
                    </div>
                    <button
                      onClick={() => downloadFile(ticket.ticketUrl, ticket.ticketNo)}
                      className={`${styles.downloadButton} ${downloadProgress[ticket.ticketNo] === 100 ? styles.downloadedButton : ''}`}
                    >
                      <i className="fa fa-download"></i> {"  "}
                      {downloadProgress[ticket.ticketNo] === 100 
                        ? paste(['Ýüklendi', 'Загружено', 'Downloaded']) 
                        : paste(['Ýükle', 'Скачать', 'Download'])}
                    </button>
                  </div>
                </li>
              ))}
            </ol>
            <small className={styles.warning}>
              {paste(['Teatra sahna başlamazdan 30 min öňräk barmagyňyzy haýyş edýäris.', 'Просим прийти в театр за 30 минут до начала спектакля.', 'We kindly ask you to come to the theater 30 minutes before the start of the performance.'])}
            </small>
          </div>
        </div>
      ) : (
        <div style={{ color: "red", margin: "20px" }}>
          {ticketStatus.ErrorMessage}
        </div>
      )}
    </div>
  );
};

export default VerifyTicket;
