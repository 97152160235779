import { useState, useEffect, useMemo } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import seatsOfHall from "../images/seats_hall.jpg";
import BookingCard from "../components/cards/Booking/Booking";
import "../style/booking.css";
import { useParams } from "react-router-dom";
import { getAllSpectaclesByShow, getSpectacleByShow } from "../api/spectacles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { paste } from "../utils/helpers/pasteWithLang";
import { encodeAndStore } from '../utils/helpers/crypto'

const Booking = () => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const navigate = useNavigate();

  const { id } = useParams();
  const [checkoutData, setCheckoutData] = useState([]);

  const [spectacleDetail, setSpectacleDetail] = useState({
    duration_en: "",
    duration_ru: "",
    duration_tm: "",
    show_date: "",
    spectacle_title_en: "",
    spectacle_title_ru: "",
    spectacle_title_tm: "",
    startTime: "",
    spectacle_id: "",
  });
  const [spectaclesByShow, setSpectaclesByshow] = useState();
  const getSpectacleByShowData = async () => {
    await getSpectacleByShow(id)
      .then((response) => {
        setSpectacleDetail(response);
      })
      .catch((error) => console.log(error));
  };
  const getSpectaclesByShow = async () => {
    await getAllSpectaclesByShow(id)
      .then((response) => {
        setSpectaclesByshow(response);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getSpectacleByShowData();
    getSpectaclesByShow();
    encodeAndStore('showId', id)
  }, []);

  const addToCheckout = (seat) => {
    if (seat.is_selected) {
      setCheckoutData([...checkoutData, seat]);
    } else {
      const filtered = checkoutData.filter(
        (item) => item.seat_id !== seat.seat_id
      );
      setCheckoutData(filtered);
    }
  };

  const checkoutSum = useMemo(() => {
    let sum = 0;
    for (let i = 0; i < checkoutData.length; i++) {
      sum += checkoutData[i].price;
    }
    return sum.toFixed(2);
  }, [checkoutData]);

  useEffect(() => {
    encodeAndStore("selected_seats", checkoutData);
    encodeAndStore("sum_of_tickets", checkoutSum);
  }, [checkoutData]);


  

  return (
    <main>
      <div className="text-center d-flex flex-column justify-content-center gap-1 p-4 border-bottom border-2 mb-4">
        <b>
          {paste(['Sahna', 'Спектакл', 'Spectacle'])}: {" "}
          {
            spectacleDetail[
              locale === "tm"
                ? "spectacle_title_tm"
                : locale === "ru"
                ? "spectacle_title_ru"
                : "spectacle_title_en"
            ]
          }
        </b>
        <b>{paste(['Senesi', 'Дата', 'Date'])}: {spectacleDetail.show_date}</b>
        <b>{paste(['Başlanýan wagty', 'Когда это начнется', 'Starting time'])}: {spectacleDetail.startTime}</b>
        <b>
          {paste(['Dowamlylygy', 'Непрерывность', 'Duration'])}:{" "}
          {
            spectacleDetail[
              locale === "tm"
                ? "duration_tm"
                : locale === "ru"
                ? "duration_ru"
                : "duration_en"
            ]
          }
        </b>
      </div>
      <div className="container mx-auto mb-5">
        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          className="mb-3"
          fill
        >
          <Tab
            eventKey="home"
            className="bg-white p-3"
            title={
              <>
                <b>1.1</b> <br />
                <small className="bg-info p-1 rounded-5">
                  {paste(["1.gat 1.hatar", "1. этаж 1. ряд", "1. floor 1. row"])}
                </small>
              </>
            }
          >
            <div className="d-flex justify-content-between align-items-center px-0 px-md-5 my-3">
              <div className="d-flex gap-1 align-items-center">
                <div className="mini-box empty-box"></div>
                <span>
                  {paste(["Boş ýer", "Пустое место", "Empty seat"])}
                </span>
              </div>
              <div className="d-flex gap-1 align-items-center">
                <div className="mini-box selected-box"></div>
                <span>
                  {paste(["Saýlanan ýer", "Выбранное место", "Selected seat"])}
                </span>
              </div>
              <div className="d-flex gap-1 align-items-center">
                <div className="mini-box sold-box"></div>
                <span>
                  {paste(["Satylan ýer", "Проданное место", "Sold seat"])}
                </span>
              </div>
            </div>

            <div className="main">
              {spectaclesByShow?.oneone?.map((item, index) => (
                <BookingCard
                  key={index}
                  index={index}
                  data={item}
                  onClick={(seat) => addToCheckout(seat)}
                />
              ))}
            </div>
          </Tab>

          <Tab
            eventKey="profile"
            className="bg-white p-3"
            // disabled={!spectaclesByShow?.isFullOneOne}
            title={
              <>
                <b>1.2</b> <br />
                <small className="bg-info p-1 rounded-5">
                {paste(["1.gat 2.hatar", "1. этаж 2. ряд", "1. floor 2. row"])}
                </small>
              </>
            }
          >
            <div className="d-flex justify-content-between align-items-center px-0 px-md-5 my-3">
              <div className="d-flex gap-1 align-items-center">
                <div className="mini-box empty-box"></div>
                <span>
                  {paste(["Boş ýer", "Пустое место", "Empty seat"])}
                </span>
              </div>
              <div className="d-flex gap-1 align-items-center">
                <div className="mini-box selected-box"></div>
                <span>
                  {paste(["Saýlanan ýer", "Выбранное место", "Selected seat"])}
                </span>
              </div>
              <div className="d-flex gap-1 align-items-center">
                <div className="mini-box sold-box"></div>
                <span>
                  {paste(["Satylan ýer", "Проданное место", "Sold seat"])}
                </span>
              </div>
            </div>

            <div className="main">
              {spectaclesByShow?.onetwo?.map((item, index) => (
                <BookingCard
                  key={index}
                  index={index}
                  data={item}
                  onClick={(seat) => addToCheckout(seat)}
                />
              ))}
            </div>
          </Tab>

          <Tab
            eventKey="contact"
            className="bg-white p-3"
            // disabled={!spectaclesByShow?.isFullOneTwo}
            title={
              <>
                <b>2.1</b> <br />
                <small className="bg-info p-1 rounded-5">
                {paste(["2.gat 1.hatar", "2. этаж 1. ряд", "2. floor 1. row"])}
                </small>
              </>
            }
          >
            <div className="d-flex justify-content-between align-items-center px-0 px-md-5 my-3">
              <div className="d-flex gap-1 align-items-center">
                <div className="mini-box empty-box"></div>
                <span>
                  {paste(["Boş ýer", "Пустое место", "Empty seat"])}
                </span>
              </div>
              <div className="d-flex gap-1 align-items-center">
                <div className="mini-box selected-box"></div>
                <span>
                  {paste(["Saýlanan ýer", "Выбранное место", "Selected seat"])}
                </span>
              </div>
              <div className="d-flex gap-1 align-items-center">
                <div className="mini-box sold-box"></div>
                <span>
                  {paste(["Satylan ýer", "Проданное место", "Sold seat"])}
                </span>
              </div>
            </div>

            <div className="main">
              {spectaclesByShow?.twoone?.map((item, index) => (
                <BookingCard
                  key={index}
                  index={index}
                  data={item}
                  onClick={(seat) => addToCheckout(seat)}
                />
              ))}
            </div>
          </Tab>

          <Tab
            eventKey="seats_of_hall"
            title={
              <p>
                {paste(['Zalyň ýerleşişi', 'Расположение зала', 'Seats of hall'])}
                <br />
              </p>
            }
          >
            <img
              src={seatsOfHall}
              alt="Zalyň ýerleşişi"
              className="seats-of-hall"
            />
          </Tab>
        </Tabs>

        <div className="checkouts">
          {checkoutData.length > 0 ? <h2>
            {paste(["Saýlanan ýerler", "Выбранные места", "Selected seats"])}
          </h2> : ""}
          <div className="checkout_content">
            {checkoutData?.map((seat) => (
              <div className="checkout" key={seat.seat_id}>
                <span>{paste(['Ýer', 'Место', 'Seat'])}: </span>
                <span>
                  {seat[
                    locale === "tm"
                      ? "floor_name_tm"
                      : locale === "ru"
                      ? "floor_name_ru"
                      : "floor_name_en"
                  ] + "  "}
                </span>
                <span>{"  " + seat.line_name + "" + seat.seatNumber}</span>
              </div>
            ))}
          </div>
          {checkoutData.length > 0 && (
            <div>
          
              <p>
                {paste(['Jemi', 'Общий', 'Total'])}: <b>{checkoutData.length}</b> {paste(['bilet  we ', 'билет и', 'ticket and'])} <b>{checkoutSum} tmt</b>
                
              </p>
              <button
                className="proceed_btn"
                onClick={() => navigate("/checkout")}
              >
                {paste(['Dowam et', 'Продолжить', 'Proceed'])}
              </button>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default Booking;
