import styles from "./Checkout.module.css";
// form controller
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { checkout } from "../../api/ticket";
import halkbank from "../../images/halkbank.webp";
import { paste } from "../../utils/helpers/pasteWithLang";
import { decodeFromStorage } from '../../utils/helpers/crypto'

Yup.setLocale({
  mixed: {
    required: 'Bu meýdançany hökman doldurmaly',
  },
  string: {
    max: 'Bu meýdança iň köp {max} simwoldan ybarat bolmaly',
    email: 'Dogry email adresini giriziň',
    matches: 'Telefon belgisi dogry formatda bolmaly, meselem: 65999999 ýa-da 71999999',
  },
});

const Checkout = () => {

  const sSeatsLS = decodeFromStorage("selected_seats");
  const parsedSelectedSeats =
    sSeatsLS !== undefined || sSeatsLS !== null ? JSON.parse(sSeatsLS) : [];
  const [selectedSeats] = useState(parsedSelectedSeats);
 

  const formik = useFormik({
    initialValues: {
      username: "",
      phone: "",
      email: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required().max(50),
      phone: Yup.string()
        .required()
        .matches(new RegExp(/^(6[1-5]|71)\d{6}$/)),
      email: Yup.string().nullable().max(50).email(),
    }),
    onSubmit: async (values, { resetForm }) => {
      const seatIds = selectedSeats.map((sseat) => sseat.seat_id);
      const showId = decodeFromStorage("showId");
      const sumOfTickets = decodeFromStorage("sum_of_tickets");
      const data = {
        ...values,
        seatIds,
        showId,
        sumOfTickets: (+sumOfTickets + 0.1).toFixed(2),
      };
      try {
        const responseCheckout = await checkout(data);
        if (responseCheckout.status === 200) {
          const { formUrl } = responseCheckout.data;
          window.location.href = formUrl;
        } else if (responseCheckout.status === 500) {
          alert(responseCheckout.response.data.message);
        }
      } catch (error) {
        console.error(error);
        if (error.status === 500) {
          alert(error.response.data.message);
        }
      }
    },
    validateOnBlur: true,
    validateOnChange: true,
  });

  const getErrorMessage = (field) => {
    if (formik.touched[field] && formik.errors[field]) {
      return formik.errors[field];
    }
    return null;
  };

  const focusErrorInput = () => {
    setTimeout(() => {
      const errorFields = Object.keys(formik.errors);
      if (errorFields.length > 0) {
        const firstErrorField = errorFields[0];
        document.getElementsByName(firstErrorField)[0].focus();
      }
    }, 0);
  };

  return (
    <div className={styles.main}>
      <div className={styles.login_container}>
        <h1 className={styles.head_text}>
          {paste(['Maglumatlaryňyzy giriziň', 'Введите ваши данные', 'Enter your data'])}
        </h1>
        <div>
          <form
            onSubmit={(e) => {
              formik.handleSubmit(e);
              focusErrorInput();
            }}
          >
            <input
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              type="text"
              className={styles.input}
              placeholder={paste(['Adyňyz we Familiýaňyz', 'Ваше имя и фамилия', 'Your name and surname'])}
              disabled={formik.isSubmitting}
              autoComplete="off"
            />
            {getErrorMessage("username") && (
              <div className={styles.error}>{getErrorMessage("username")}</div>
            )}
            <div className={styles.phone_wrapper}>
              <span className={styles.phone_prefix}>+993</span>
              <input
                name="phone"
                value={formik.values.phone}
                maxLength={8}
                onChange={(e) => {
                  const { value } = e.target;
                  if (/^\d*$/.test(value)) {
                    formik.handleChange(e);
                  }
                }}
                type="text"
                className={`${styles.phone_input} ${styles.input}`}
                placeholder={paste(['Telefon belgiňiz', 'Ваш номер телефона', 'Your phone number'])}
                disabled={formik.isSubmitting}
                autoComplete="off"
              />
            </div>
            {getErrorMessage("phone") && (
              <div className={styles.error}>{getErrorMessage("phone")}</div>
            )}
            <input
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              type="email"
              className={styles.input}
              placeholder={paste(['Email', 'Электронная почта', 'Email'])}
              disabled={formik.isSubmitting}
              autoComplete="off"
            />
            {getErrorMessage("email") && (
              <div className={styles.error}>{getErrorMessage("email")}</div>
            )}
            <div>
              {paste(['Biletler', 'Билеты', 'Tickets'])}: <b>{decodeFromStorage("sum_of_tickets")} tmt</b>
            </div>
            <div>
              {paste(['Bankyň hyzmaty', 'Банковское обслуживание', 'Bank service'])}: <b>0.10 tmt</b>
            </div>

            <div>
            {paste(['Jemi', 'Общий', 'Total'])}: <b>{selectedSeats.length}</b> {paste(['bilet', 'билет', 'ticket'])} -{" "}
              <b>
                {(+decodeFromStorage("sum_of_tickets") + 0.1).toFixed(2)}{" "}
                tmt
              </b>
            </div>
            <div className={styles.announcement_field}>
              <img
                src={halkbank}
                alt="Halkbank"
                style={{ borderRadius: "10px", flex: "1" }}
                width={150}
              />
              <p style={{ flex: "3" }}>
                <small>
                  <i>
                    {
                      paste([`Hormatly tomaşaçylar häzirki wagtda diňe Altyn Asyr 
                    bank kartlary arkaly töleg amala aşyryp bilersiňiz!`, `Уважаемые покупатели, в настоящее время вы можете оплатить только через банковские карты Altyn Asyr!`, `Dear customers, at the moment you can only pay through Altyn Asyr bank cards!`])
                    }
                  </i>
                </small>
              </p>
            </div>
            <p>
                <i>
                  {
                    paste(['Ýakyn günlerde ähli bank kartlaryndan töleg amala aşyrylar!', 'В ближайшие дни оплата будет доступна со всех банковских карт!', 'In the coming days, payment will be available from all bank cards!'])
                  }
                </i>
              </p>
            <button
              disabled={formik.isSubmitting}
              className={styles.button}
              type="submit"
            >
              {paste(['Töleg', 'Оплата', 'Payment'])} {formik.isSubmitting && "..."}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
