import CryptoJS from 'crypto-js';

const secretKey = '2088_*#NARUT***';

export function encodeAndStore(key, value) {
  const stringValue = typeof value === 'string' ? value : JSON.stringify(value);
  const encryptedValue = CryptoJS.AES.encrypt(stringValue, secretKey).toString();
  sessionStorage.setItem(key, encryptedValue);
}

export function decodeFromStorage(key) {
  const encryptedValue = sessionStorage.getItem(key);
  if (!encryptedValue) return null;

  try {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
    const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedValue;
  } catch (error) {
    console.error('Decryption failed:', error);
    return null;
  }
}