import { host } from "../api";

export const getAllSeats = async () => {
  const response = await host
    .get("/api/ticket/getallseats")
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export const getAllFloors = async () => {
  const response = await host
    .get("/api/ticket/getallfloors")
    .then((res) => res.data)
    .catch((error) => error);
    return response
};


export const getAllRows = async () => {
  const response = await host
    .get("/api/ticket/getallrows")
    .then((res) => res.data)
    .catch((error) => error);
    return response
};


export const getAllLines = async () => {
  const response = await host
    .get("/api/ticket/getalllines")
    .then((res) => res.data)
    .catch((error) => error);
    return response
};


export const checkout = async data => {
  const response = await host
    .post("/api/ticket/checkout", data)
    .then((res) => res)
    .catch((error) => error);
    return response
};



export const verifyTicket = async orderId => {
  const response = await host
    .get(`/api/ticket/verify-ticket?orderId=${orderId}`)
    .then((res) => res)
    .catch((error) => error);
    return response
};